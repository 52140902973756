import React from 'react';
import { ThemeProvider, createTheme, StyledEngineProvider} from '@mui/material/styles';
import { ThemeProps } from './ThemeProvider.types';
import { makeStyles } from '@mui/styles';
import { InfoBarProvider } from '../Contexts/InfoBarContext';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import weakMemoize from '@emotion/weak-memoize';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    tertiary: Palette['primary'];
    quartiary: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
    quartiary: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  typography: {
    fontFamily: 'Noto Sans',
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 400,
    fontWeightBold: 700
  },
  palette: {
    primary: {
      main: '#FF596B',
    },
    secondary: {
      main: '#ECECEC',
      dark: '#5a5a5a',
    },
    tertiary: {
      main: '#ff7e8d',
    },
    quartiary: {
      main: '#FFFFFF',
      dark: '#f3f3f3'
    }
  },
  appDrawer: {
    width: 113
  },
  dataTable: {
    minWidth: 650,
    maxHeight: 600,
    minHeight: 300
  },
  components: {
    MuiGrid: {
      item: {
        textAlign: 'left'
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '6.5px 16px',
          margin: '0 4px !important',
        }
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            fontWeight: 'bold',
            margin: '10px',
            boxShadow: 'none',
          }
        }
      ]
    },
    MuiPaper: {
      root: {
        boxShadow: 'none'
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.6) !important',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-error .MuiOutlinedInputNotchedOutline': {
            borderColor: '#FF3232',
          }
        }
      }
    }
  }
});

export const globalStyles = makeStyles({
  gridToLeft: {
    textAlign: 'left'
  },
  standardMargin: {
    margin: '9px'
  },  
  labelText: {
    fontFamily: '\'Noto Sans\', sans-serif',
    color: '#222222 !important',
    fontStyle: 'normal',
    fontWeight: 'bold',
    display: 'flex'
  },
  labelTextError: {
    fontFamily: '\'Noto Sans\', sans-serif',
    color: '#d32f2f !important',
    fontStyle: 'normal',
    fontWeight: 'bold',
    display: 'flex'
  }
});

export const globalClassesRaw = () => ({
  gridToLeft: {
    textAlign: 'left'
  },
  standardMargin: {
    margin: '9px'
  },  
  labelText: {
    fontFamily: '\'Noto Sans\', sans-serif',
    color: '#222222 !important',
    fontStyle: 'normal',
    fontWeight: 'bold',
    display: 'flex',
    maxWidth: '70vw'
  },
  labelTextError: {
    fontFamily: '\'Noto Sans\', sans-serif',
    color: '#d32f2f !important',
    fontStyle: 'normal',
    fontWeight: 'bold',
    display: 'flex'
  }
});

export const getTheme = () => {
  return theme;
};

const memoizedCreateCacheWithContainer = weakMemoize((container: Node) => {
  const newCache = createCache({ container, key: 'global-css-verrific', prepend: true, speedy: true });
  return newCache;
});

export const Theme: React.FC<ThemeProps> = ({children}: ThemeProps) => {
  // const cache = createEmotionCache();
  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <InfoBarProvider showingTime={30000}>
          {children}
        </InfoBarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export const CachedTheme: React.FC<ThemeProps> = ({children}: ThemeProps) => {
  // const cache = createEmotionCache();
  return (
    <StyledEngineProvider injectFirst={false}>
      <CacheProvider value={memoizedCreateCacheWithContainer(document.head)}>
        <ThemeProvider theme={theme}>
          {/* <CssBaseline /> */}
          <InfoBarProvider showingTime={30000}>
            {children}
          </InfoBarProvider>
        </ThemeProvider>
      </CacheProvider>
    </StyledEngineProvider>
  );
};